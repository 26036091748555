import React from 'react'
import styled from 'styled-components'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Wapper = styled.div`
  .slick-slide {
    box-sizing: border-box;
    padding: 8px;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #00000055;
  }

  .slick-dots li.slick-active botton:before,
  .slick-dots li button:before {
    color: #888;
  }
`

const Gallery = ({ images }) => {
  const setting = {
    infinite: true,
    dots: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <Wapper>
      <Slider {...setting}>
        {images.map(image => {
          return (
            <Zoom key={image}>
              <img
                style={{
                  margin: '5px',
                }}
                alt="图集"
                src={image}
              />
            </Zoom>
          )
        })}
      </Slider>
    </Wapper>
  )
}

export default Gallery
