import React from 'react'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Image = ({ src }) => {
  return (
    <Zoom>
      <img alt="图片" src={src}></img>
    </Zoom>
  )
}

export default Image
